import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MeteoService {
  private url = "https://www.prevision-meteo.ch/services/json";
  private ville: string = "toulouse";
  constructor(private http: HttpClient) {}

  getMeteo(): Observable<any> {
    return this.http.get(`${this.url}/${this.ville}`).pipe(
      map((donnees) => {
        return {
          city_info: donnees["city_info"],
          current_condition: donnees["current_condition"],
          hourly_data: donnees["fcst_day_0"]["hourly_data"]
        };
      })
    );
  }
}
