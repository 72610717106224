import { Component, OnInit } from "@angular/core";
import { MeteoService } from "../services/meteo.service";
import * as $ from "jquery";

@Component({
  selector: "app-widget-meteo",
  templateUrl: "./widget-meteo.component.html",
  styleUrls: ["./widget-meteo.component.scss"],
})
export class WidgetMeteoComponent implements OnInit {
  constructor(private meteoService: MeteoService) {}

  private city_info: string = "";
  private current_condition: any;
  private donnees = [];
  private error = false;

  ngOnInit() {
    this.meteoService.getMeteo().subscribe(
      (data) => {
        this.city_info = data.city_info;
        this.current_condition = data.current_condition;
        const regex = /\./ig;
        this.current_condition.date=this.current_condition.date.replace(regex, '\/');
        let d = new Date();
        let h = d.getHours();
        let i = 0;
        $.each(data.hourly_data, (k, v) => {
          //console.log('k:'+k, 'v:'+v);
          if (i >= h && i < h + 5) {
            v.hour = k;
            this.donnees.push(v);
          }
          i++;
        });
        this.error = false;
      },
      (error) => {
        this.error = true;
      },
      () => {
        console.log("fini");
      }
    );
  }
}
